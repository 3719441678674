var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"quizShell"},[_c('v-card',{staticClass:"quizCard",attrs:{"shaped":""}},[(!_vm.customHeader)?_c('div',{staticClass:"quizCard__header",style:({
        backgroundColor: _vm.colorScheme || 'var(--v-primary-base)'
      })},[_c('div',{staticClass:"quizCard__header--questionCounter"},[_c('span',{staticClass:"font-weight-black white--text"},[_vm._v(" "+_vm._s(_vm.$t('Question ') + _vm.currentQuestionNumber + _vm.$t(' of ') + _vm.totalNumberOfQuestions)+" ")])]),_c('div',{staticClass:"gameInfo"},[_c('div',{class:{ 'gameInfo--scoreCardNormal': !_vm.$vuetify.breakpoint.xs }},[_c('div',{staticClass:"font-weight-black pl-2 white--text",style:({ textAlign: 'center' })},[_c('v-icon',{staticClass:"ml-1 mr-1",attrs:{"color":"white"}},[_vm._v("mdi-check-circle-outline")]),_c('span',[_vm._v(_vm._s(_vm.correctCount))]),_c('v-icon',{staticClass:"ml-1 mr-1",attrs:{"color":"white"}},[_vm._v("mdi-close-circle-outline")]),_c('span',[_vm._v(" "+_vm._s(_vm.wrongCount)+" ")])],1)]),_c('div',{staticClass:"pl-4",class:_vm.$vuetify.breakpoint.xs
              ? 'gameInfo--settingsMobile'
              : 'gameInfo--settingsNormal',style:({
            backgroundColor: _vm.colorScheme || 'var(--v-primary-base)'
          })},[(_vm.levels)?_c('LevelSelector',{attrs:{"levels":_vm.levels,"initialLevel":_vm.selectedLevel},on:{"onSelect":_vm.restartWithSelectedLevel}}):_vm._e(),_c('HowToPlay',{staticClass:"mr-2",attrs:{"i18n_instructions_key":_vm.i18n_instructionsKey,"hideLabel":true,"color":"white","iconSize":"x-large"}}),(_vm.showAudioSetting)?_c('AudioPlayer',{attrs:{"hideButton":"true","hideButtonName":"true","onDarkBackground":"true"}}):_vm._e()],1)])]):_c('div',{staticClass:"text-center white--text text-h6 pa-2",style:({
        backgroundColor: _vm.colorScheme || 'var(--v-primary-base)'
      })},[_vm._v(" "+_vm._s(_vm.customHeader)+" ")]),_c('v-row',{staticClass:"pa-2 mt-5 justify-center",attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"text-align":"center"}},[_vm._t("question")],2)],1),_c('div',{staticClass:"answerSlot pb-5"},[_c('v-row',{staticClass:"pa-2 justify-center",attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"text-align":"center"}},[_vm._t("answer")],2)],1),(_vm.displaySubmit)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"raised":"true","color":"primary","small":""},on:{"click":_vm.submitAnswer}},[_vm._v(_vm._s(_vm.$t('Submit Answer'))+" ")])],1)],1):_vm._e(),(_vm.displayPreviousQuestionBtn || _vm.displayNextQuestionBtn)?_c('v-row',{attrs:{"no-gutters":""}},[(_vm.displayPreviousQuestionBtn)?_c('v-col',[_c('v-btn',{attrs:{"raised":"true","color":"primary","small":"","text":""},on:{"click":_vm.goToPreviousQuestion}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-left-thin ")]),_vm._v(" "+_vm._s(_vm.$t('Previous')))],1)],1):_vm._e(),(_vm.displayNextQuestionBtn)?_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{attrs:{"raised":"true","color":"primary","small":"","text":""},on:{"click":_vm.goToNextQuestion}},[_vm._v(_vm._s(_vm.$t('Next'))+" "),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-right-thin ")])],1)],1):_vm._e()],1):_vm._e(),(_vm.soundEffects)?_c('div',{staticClass:"ml-3 mr-3 d-flex justify-end"},[_c('RoundVolumeControl',{attrs:{"min":"0","max":"25","startAngle":"-45","endAngle":"225","default":"12.5","pause":_vm.pauseBackgroundMusic,"backgroundMusicSrc":_vm.backgroundMusic},on:{"muted":_vm.mute,"volumeChange":_vm.volumeChange}})],1):_vm._e()],1)],1),_c('GoBackButton')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }